import React from 'react';

import { createRenderer } from 'fela';
import { rehydrate } from 'fela-dom';
import { RendererProvider } from 'react-fela';

import config from './fela.config';

export const wrapRootElement = ({ element }) => {
    const renderer = createRenderer(config);
    rehydrate(renderer);
    return <RendererProvider renderer={renderer}>{element}</RendererProvider>;
};
